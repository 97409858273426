<template>

  <section id="cultura-home">
    
    <div class="row">
      <div class="col-12 my-3">
        <h3 class="section-head-title mb-0">
          Cultura y valores de la empresa
        </h3>
      </div>
    </div>

		<Loading 
			v-if="loading"
			pixeles="150px"
		/>

    <template v-if="!loading">  
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="card card-custom card-shadow border-round-10 border-0 mb-3">
            <div class="card-body">
              <div class="items">
                <div v-for="(c, i) in culture.descripcion" class="item" :key="i" >
                  <h6 v-if="c.tipo_descripcion.nombre == 'Titulo'" class="card-title font-title-bold fs-4 mb-2" >
                    {{ c.titulo }}
                  </h6>
                  <div v-if="c.tipo_descripcion.nombre == 'Texto'" class="text-secondary" v-html="c.texto" >
                  </div>
                  <figure v-if="c.tipo_descripcion.nombre == 'Imagen'" class="figure" >
                    <img :src="c.url_documento" class="img-fluid rounded-4" />
                  </figure>
                  <div v-if="c.tipo_descripcion.nombre == 'Documento'" class="documento" >
                    <a :href="c.url_documento" class="link-secondary" >
                      Ver documento
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card card-shadow border-round-10 border-0 mb-3">
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="ms-2 me-auto">
                <h6 class="font-main-bold text-secondary">Cultura de nuestra empresa</h6>
                <p class="text-secondary mb-0">
                  {{ dateFormat(culture.fecha_creacion) }}
                </p>
              </div>
              <button 
                @click="editCultura(culture.id_cultura)"
                class="btn btn-custom-color-white border me-2"
              >
                <font-awesome-icon icon="pencil"/>
              </button>
            </div>
          </div>
  
          <div class="pb-2 mb-4 border-bottom border-gray"></div>
  
          <div 
            v-for="(val, i) in culture.valores" 
            :key="i"
            class="card card-shadow border-round-10 border-0 mb-3">
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="ms-2 me-auto">
                <h6 
                  class="font-main-bold mb-1"
                  :style="`color: ${val.color};`"
                >
                  {{ val.nombre }}
                </h6>
                <p class="text-secondary mb-0">
                  {{ handleAlbumTitle(val.descripcion) }}
                </p>
              </div>
              <div class="dropdown dropdown-custom d-flex justify-content-center">
                <button 
                  class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                  type="button" 
                  data-bs-toggle="dropdown">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a 
                      @click="openView(val)"
                      class="dropdown-item" 
                      href="javascript:"
                      data-bs-toggle="modal" 
                      data-bs-target="#modal-valor"
                    >
                      <font-awesome-icon 
                        class="pe-2 color-main" 
                        icon="eye"
                      />
                      Vista previa
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="editValorCultura(val.id_valor)"
                      class="dropdown-item" 
                      href="javascript:"
                    >
                      <font-awesome-icon 
                        class="pe-2 color-main" 
                        icon="pen"
                      />
                      Editar
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="deleteCulturaInit(val)"
                      class="dropdown-item" 
                      href="javascript:"
                    >
                      <font-awesome-icon 
                        class="pe-2 color-main" 
                        icon="trash-alt"
                      />
                      Eliminar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--Modal-->
    <div 
      class="modal fade" 
      id="modal-valor" 
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button 
              type="button" 
              class="btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close">
            </button>
          </div>
          <div class="modal-body text-center">
            <figure class="figure">
              <img 
                :src="view.imagen_url"
                class="img-obj img-obj-150 rounded-circle"
              />
            </figure>
            <h3 
              class="font-main-bold" 
              :style="'color:' + view.color">
              {{ view.nombre }}
            </h3>
            <p class="text-secondary">
              {{ view.descripcion }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para eliminar video -->
    <Question
      v-if="open_modal_delete"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelDelete"
      @accept="deleteValorCultura"
    />

  </section>

</template>

<script>
  import moment from "moment-timezone";
  import 'moment/locale/es.js'; // Import Spanish locale
  
  import Question from "../Modales/Question.vue";
  import { mapActions, mapState } from "vuex";

  //FF
  import Loading from "@/components/Loading.vue";

  export default {
    components: { 
      Question,
      Loading
    },
    data() {
      return {
        question_modal_msg: "¿Estás seguro que deseas eliminar este Valor?",
        culturaToDelete: {},
        open_modal_delete: false,
        modal_status: true,
        culturas_empresa: [],
        cultura_selected: {},
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_usuario: this.$ls.get("user").id_usuario,
        meses: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        option: "",
        view: {},

        //FF
        loading: ''
      };
    },
    computed: {
      ...mapState("cultureModule", ["culture", "culture_action"]),
    },
    watch: {
      actions() {
        setTimeout(() => {
          this.show_spinner = false;
        }, 500);
        this.valoresList(this.culture_actions_list);
      },
    },
    async created() {
      moment.locale('es');
      await this.getCulturas();
    },
    methods: {
      ...mapActions("cultureModule", ["deleteValor", "getAllCultura"]),
      
      openView(val) {
        this.view = val;
      },
      handleAlbumTitle(title) {
        if (title.length > 59) {
          return title.slice(0, 60) + "...";
        } else return title;
      },
      // Funciona para obtener las culturas de la empresa
      async getCulturas() {
        this.loading = true;
        let data_ = {
          id_empresa: this.id_empresa
        }
        try {
          await this.getAllCultura(data_);
        }
        catch(error){
          console.log(error);
        }
        this.loading = false;
      },
      //va a editar cultura
      editCultura(Cultura) {
        this.$router.push({
          name: "culture-add",
          params: { cultura: Cultura },
        });
      },
      //va a editar cultura
      editValorCultura(val) {
        this.$router.push({
          name: "culture-valor-edit",
          params: { id: val },
        });
      },
      //cancela la eliminacion
      cancelDelete() {
        this.open_modal_delete = false;
        this.culturaToDelete = {};
      },
      //elimina cultura
      deleteCulturaInit(cul) {
        this.culturaToDelete = cul;
        this.open_modal_delete = true;
      },
      //elimina cultura
      async deleteValorCultura() {
        let data_ = {
          id_empresa: this.id_empresa,
          id_valor: this.culturaToDelete.id_valor,
          id_cultura: this.culture.id_cultura,
          id_usuario: this.id_usuario,
        };
        await this.deleteValor(data_).then(res =>{
          if(res != null){
            this.$toast.open({
              message:'El valor se ha eliminado correctamente.',
              type:'success',
              duration:5000,
              position:'top-right'
            });
          }else{
            this.$toast.open({
              message:'Ocurrió un error al eliminar el valor, intentalo nuevamente.',
              type:'error',
              duration:0,
              position:'top-right'
            });
          }
        });
        this.open_modal_delete = false;
      },
      dateFormat(dateString) {
        const date = moment(dateString);
        return date.format('D [de] MMMM');
      },
      valoresList(arr) {
        for (let i = 0; i < arr.length; i++) {
          const e = arr[i];
          switch (e.action) {
            case "delete":
              try {
                this.culturas_empresa.valores =
                  this.culturas_empresa.valores.filter(
                    (li) => li.id_valor != e.data.id_valor
                  );
              } catch (error) {
                if (error) {
                  console.log("err");
                }
              }
              break;
            case "add":
              try {
                this.culturas_empresa.valores =
                  this.culturas_empresa.valores.filter(
                    (li) => li.id_valor != e.data.id_valor
                  );
              } catch (error) {
                if (error) {
                  console.log("err");
                }
              }
              this.culturas_empresa.valores.push(e.data);
              break;
            default:
              break;
          }
        }
      },
    },
  };
</script>
